import { once } from 'lodash';
import { ActionContext, Module, Store } from 'vuex';
import { make, commit, dispatch } from 'vuex-pathify';
import vuexStore, { IRootState } from '..';
import { IPermissionProfileModel, IRadCaseModel, IUserModel, IFileIdStatus, IRadCaseResponse, IImageModel, IRadCaseReportModel, IRadCaseReportStatItem } from '@/api/Models';
import FMSAxios from '@/api/FMSAxios';

export interface IRadCaseState {
  radcase?: IRadCaseModel;
  radcases: IRadCaseResponse[];
  images: IImageModel[];
}

const _state: IRadCaseState = {
  radcase: undefined,
  radcases: [],
  images: []
};

const radcase: Module<IRadCaseState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state),
    setRadcase (state, radcaseData: IRadCaseModel) {
      state.radcase = radcaseData;
    },
    setRadcases (state, radcases: IRadCaseResponse[]) {
      state.radcases = radcases;
    }
  },
  getters: {
    getIsConfigValid (state): boolean {
      return !!state.radcase;
    },
    radcase (state): IRadCaseModel | undefined {
      return state.radcase;
    },
    radcases (state): IRadCaseModel[] {
      return state.radcases;
    },
    images (state): IImageModel[] {
      return state.images;
    }
  },
  actions: {
    initialize: once(async (context: ActionContext<IRadCaseState, IRootState>, store: Store<IRootState>) => {
      await dispatch('radcase/fetch');
      store.watch((state) => {
        return {
          clinicId: state.clinic.clinic?.id
        };
      }, (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('radcase/fetch');
        }
      });
    }),
    async fetch (): Promise<void> {
      const permissionProfile: IPermissionProfileModel = vuexStore.get('permissionProfile/profile');
      const user: IUserModel = vuexStore.get('user/user');
      const params: { status: number; clinicId?: number } = { status: 1 };
      if (permissionProfile.radiologistCase || permissionProfile.root) {
        if (permissionProfile.doctorCase) {
          params.clinicId = user.clinicId;
        }
        const response = await FMSAxios.get<IRadCaseResponse[]>('/radcase/cases', {
          params: params
        });
        commit('radcase/setRadcases', response.data);
      }
    },
    async fetchCasesByStatus (context, status: number): Promise<void> {
      const permissionProfile: IPermissionProfileModel = vuexStore.get('permissionProfile/profile');
      const user: IUserModel = vuexStore.get('user/user');
      const params: { status: number; clinicId?: number } = { status };
      if (permissionProfile.radiologistCase || permissionProfile.root || permissionProfile.doctorCase) {
        if (permissionProfile.doctorCase) {
          params.clinicId = user.clinicId;
        }
        const response = await FMSAxios.get<IRadCaseResponse[]>('/radcase/cases', {
          params: params
        });
        commit('radcase/setRadcases', response.data);
      }
    },
    async fetchCasesStats (context, status: number): Promise<IRadCaseResponse[] | undefined> {
      const permissionProfile: IPermissionProfileModel = vuexStore.get('permissionProfile/profile');
      const user: IUserModel = vuexStore.get('user/user');
      const params: { status: number; clinicId?: number } = { status };
      if (permissionProfile.radiologistCase || permissionProfile.root || permissionProfile.doctorCase) {
        if (permissionProfile.doctorCase) {
          params.clinicId = user.clinicId;
        }
        const response = await FMSAxios.get<IRadCaseResponse[]>('/radcase/cases/stat', {
          params: params
        });
        return response.data;
      }
      return undefined;
    },
    async fetchCasesForReportStats (
      context: ActionContext<IRadCaseState, IRootState>,
      payload: {
        status?: number;
        modality?: string;
        priority?: string;
        submitdateFrom?: Date;
        submitdateTo?: Date;
      }
    ): Promise<IRadCaseReportStatItem[] | undefined> {
      const permissionProfile: IPermissionProfileModel = vuexStore.get('permissionProfile/profile');
      const user: IUserModel = vuexStore.get('user/user');
      const params: { status: number; clinicId?: number; modality?: string; priority?: string; submitdateFrom?: Date; submitdateTo?: Date } = {
        status: payload.status ?? 0,
        modality: payload.modality ?? '',
        priority: payload.priority ?? '',
        submitdateFrom: payload.submitdateFrom ?? undefined,
        submitdateTo: payload.submitdateTo ?? undefined
      };
      if (permissionProfile.radiologistCase || permissionProfile.root || permissionProfile.doctorCase) {
        if (permissionProfile.doctorCase) {
          params.clinicId = user.clinicId;
        }
        try {
          const response = await FMSAxios.get<IRadCaseReportStatItem[]>('/radcase/cases/reportstat', { params });
          return response.data;
        } catch (e) {
          return undefined;
        }
      }
      return undefined;
    },

    async fetchCaseById (context, caseId: string): Promise<IRadCaseModel> {
      const response = await FMSAxios.get<IRadCaseModel>(`/radcase/${caseId}`);
      commit('radcase/setRadcase', response.data);
      return response.data;
    },
    async fetchCaseByStudyId (context, studyId: string): Promise<IRadCaseModel> {
      const response = await FMSAxios.get<IRadCaseModel>(`/radcase/getByStudy/${studyId}`);
      commit('radcase/setRadcase', response.data);
      return response.data;
    },
    async fetchCaseFileIdsByCaseId (context, caseId: string): Promise<IFileIdStatus[]> {
      const response = await FMSAxios.get<IFileIdStatus[]>(`/radcase/fileIds/${caseId}`);
      const radiographs = response.data.filter(file => file.isFromReceiver);
      if (radiographs) {
        const dicomImages: IImageModel[] = radiographs.map(file => ({
          id: file.imageId ?? 0, // Handle undefined case if necessary
          userId: undefined,
          studyId: 0, // Set appropriate default value or fetch it accordingly
          bodyPart: '', // Set default or placeholder value
          bodyPartView: '', // Set default or placeholder value
          animalSize: undefined, // Set default or placeholder value if necessary
          modality: '', // Set default or placeholder value
          filePath: '', // Set default or placeholder value
          sopInstanceUID: '', // Set default or placeholder value
          seriesInstanceUID: '', // Set default or placeholder value
          sensor: '', // Set default or placeholder value
          createdOn: undefined, // Set default or placeholder value if necessary
          isRejected: false, // Set default value
          isAutoSent: false // Set default value
        }));
        commit('radcase/setImages', dicomImages);
      }
  
      return response.data;
    },
    async fetchCaseFileById (context, fileId: string): Promise<Blob> {
      const response = await FMSAxios.get(`/radcase/file/${fileId}`, { responseType: 'blob' });
      return response.data;
    },
    async fetchCaseForReport (context, id: string): Promise<IRadCaseReportModel> {
      const response = await FMSAxios.get(`/radcase/report/${id}`);
      return response.data;
    },

    async submitCase (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    async saveCase (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    async takeCase (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/take', formData);
    },
    async radiologistSubmit (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/radiologistSubmit', formData);
    },
    async cropAndSave (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/cropAndSave', formData);
    },
    async requestMoreInfo (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/requestmore', formData);
    },
    async cancelCase (context, formData: FormData): Promise<void> {
      await FMSAxios.post('radcase/cancel', formData);
    }
  },
  namespaced: true
};

export default radcase;
