









































































import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import { get } from 'vuex-pathify';
import { IRadCaseReportStatItem } from '@/api/Models';
import { format } from 'date-fns';

type SortableKeys = 'id' | 'status' | 'modality' | 'priority' | 'submitOn'; // Valid keys for sorting

export default Vue.extend({
  components: {
    PageTemplate
  },
  data () {
    return {
      selectedStatus: '',
      selectedModality: '',
      selectedPriority: '',
      submitDateFrom: '',
      submitDateTo: '',
      filteredTotal: 0,
      sortKey: 'id' as SortableKeys, // Define sort key with the SortableKeys type
      sortAsc: true,
      caseStatuses: [
        { id: 1, name: 'Available' },
        { id: 2, name: 'Assigned' },
        { id: 3, name: 'Waiting' },
        { id: 4, name: 'Finalized' },
        { id: 5, name: 'Cancelled' }
      ],
      modalities: [
        { id: 1, name: 'XRay' },
        { id: 2, name: 'CT' },
        { id: 3, name: 'UltraSound' }
      ],
      priorities: [
        { id: 1, name: '24 Hours' },
        { id: 2, name: '6 Hours' },
        { id: 3, name: 'Urgent' }
      ],
      cases: get('radcase/radcases'),
      filteredCases: [] as IRadCaseReportStatItem[] // Ensure types for the response
    };
  },
  computed: {
    totalCases (): number {
      return this.filteredCases.length;
    }
  },
  watch: {
    $route () {
      this.fetchCases();
    }
  },
  mounted () {
    this.fetchCases();
  },
  methods: {
    async fetchCases () {
      const params = {
        status: this.selectedStatus,
        modality: this.selectedModality,
        priority: this.selectedPriority,
        submitdateFrom: this.submitDateFrom,
        submitdateTo: this.submitDateTo
      };

      try {
        const response: IRadCaseReportStatItem[] | undefined = await this.$store.dispatch('radcase/fetchCasesForReportStats', params);
        if (response) {
          this.filteredCases = response;
          this.filteredTotal = response.length;
        } else {
          this.filteredCases = [];
          this.filteredTotal = 0;
        }
      } catch (error) {
        console.error('Error fetching cases:', error);
        this.filteredCases = [];
        this.filteredTotal = 0;
      }
    },
    sortBy (key: SortableKeys) {
      this.sortAsc = this.sortKey === key ? !this.sortAsc : true;
      this.sortKey = key;
    },
    getStatusName (statusId: number) {
      const currentStatus = this.caseStatuses.find(status => status.id === statusId);
      return currentStatus ? currentStatus.name : 'Unknown';
    },
    getPriorityBadgeClass (priority: string) {
      switch (priority) {
        case '24 Hours': return 'badge badge-info';
        case '6 Hours': return 'badge badge-warning';
        case 'Urgent': return 'badge badge-danger';
        default: return 'badge badge-secondary';
      }
    },
    formatDate (date: Date) {
      return date ? format(new Date(date), 'YYYY-MM-dd HH:mm:ss') : 'N/A';
    }
  }
});
