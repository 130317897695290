/* eslint-disable */


export enum ClinicType {
    Veterinary = 'Veterinary',
    Human = 'Human',
}

export enum AnimalSizes {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export enum ImageFileType {
    Jpg = 'Jpg',
    Dcm = 'Dcm',
}

export enum Species {
    Canine = 'Canine',
    Feline = 'Feline',
    Equine = 'Equine',
}

export enum EntityStatus {
    Inactive = 'Inactive',
    Active = 'Active',
}

export enum Genders {
    Male = 'Male',
    Female = 'Female',
}

export enum Modality {
    General = 'General',
    Dental = 'Dental',
    Unspecified = 'Unspecified',
}

export enum FocalSizes {
    Small = 'Small',
    Large = 'Large',
}

export enum RadCaseStatus {
    Draft = 'Draft',
    Available = 'Available',
    Assigned = 'Assigned',
    WaitForMoreInfo = 'WaitForMoreInfo',
    Finalized = 'Finalized',
    Cancelled = 'Cancelled',
}

export interface IModelBase {
    id?: number;
}

export interface IBreedModel extends IModelBase {
    name: string;
    createdOn?: Date;
    status?: EntityStatus;
    speciesId: Species;
    userId?: number;
}

export interface IClinicModel extends IModelBase {
    name: string;
    address: string;
    createdOn?: Date;
    phone: string;
    email: string;
    aeTitle: string;
    clinicType: ClinicType;
}

export interface IClinicProfilesModel extends IModelBase {
    firstName: string;
    lastName: string;
    createdOn?: Date;
    role: number;
    isActive: boolean;
    clinicId: number;
}

export interface IDicomServerModel extends IModelBase {
    serverName: string;
    aeTitle: string;
    host: string;
    port: number;
    description: string;
    createdOn?: Date;
    clinicId: number;
    autoSend: boolean;
    tls: boolean;
}

export interface IEmailConfigModel extends IModelBase {
    smtpPort: number;
    hostName: string;
    email: string;
    password: string;
    clinicId: number;
}

export interface IGeneratorTechniqueModel extends IModelBase {
    speciesId: Species;
    size: AnimalSizes;
    bodyPart: string;
    bodyPartView: string;
    fo: number;
    kv: number;
    ma: number;
    ms: number;
}

export interface IGeneratorTechniquesOverrideModel extends IModelBase {
    userId?: number;
    speciesId: Species;
    size: AnimalSizes;
    bodyPart: string;
    bodyPartView: string;
    fo: number;
    kv: number;
    ma: number;
    ms: number;
    createdOn?: Date;
}

export interface IImageRejectionModel extends IModelBase {
    imageId: number;
    rejectionReason: string;
    userId?: number;
    createdOn?: Date;
}

export interface IImageModel extends IModelBase {
    userId?: number;
    studyId: number;
    bodyPart: string;
    bodyPartView: string;
    animalSize?: AnimalSizes;
    modality: string;
    filePath: string;
    sopInstanceUID: string;
    seriesInstanceUID: string;
    sensor: string;
    createdOn?: Date;
    isRejected: boolean;
    isAutoSent: boolean;
}

export interface IPatientModel extends IModelBase {
    name: string;
    birthDate?: Date;
    createdOn?: Date;
    breedId?: number;
    ownerId?: number;
    clinicId: number;
    accessionCode: string;
    patientId: string;
    description: string;
    speciesId?: Species;
    userId?: number;
    sex?: Genders;
    neutered?: boolean;
    status?: EntityStatus;
}

export interface IPermissionProfileModel extends IModelBase {
    root: boolean;
    report: boolean;
    advancedTools: boolean;
    dicomForms: boolean;
    bulkDownload: boolean;
    doctorCase: boolean;
    radiologistCase: boolean;
    status?: EntityStatus;
}

export interface IPetOwnerModel extends IModelBase {
    name: string;
    phone: string;
    email: string;
    address: string;
    createdOn?: Date;
    clinicId: number;
    userId?: number;
    status?: EntityStatus;
}

export interface ISensorModel extends IModelBase {
    model: string;
    brand: string;
    modality: string;
    columnCellSpacing: number;
    rowCellSpacing: number;
    port: number;
    serverIp: string;
    status?: EntityStatus;
    createdOn?: Date;
}

export interface ISpecieModel extends IModelBase {
    name: string;
    createdOn?: Date;
    status?: EntityStatus;
    userId?: number;
}

export interface IStudieModel extends IModelBase {
    createdOn?: Date;
    patientId: number;
    clinicId: number;
    studyInstanceUID: string;
    sopInstanceUID: string;
    storagePath: string;
    userId?: number;
    status?: EntityStatus;
}

export interface IStudyViewerStateModel extends IModelBase {
    studyId: number;
    createdOn?: Date;
    state: string;
}

export interface IUserModel extends IModelBase {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    createdOn?: Date;
    clinicId: number;
    permissionProfileId: number;
    validUntil?: Date;
}

export interface IRadCaseModel extends IModelBase {
    createdOn?: Date;
    status?: number;
    updateOn?: Date;
    submitOn?: Date;
    studyId: number;
    clinicId: number;
    radiologistId?: number;
    referringVeterinarianId: number;
    requesterId: number;
    patientId: number;
    patientName: string;
    sex: boolean;
    birthDate?: Date;
    neutered: boolean;
    breedId: number;
    speciesId: string;
    weight: number;
    weightUnit: string;
    ownerId: number;
    ownerName: string;
    address: string;
    phone: string;
    clinicHistory: string;
    additionalNotes: string;
    modality: string;
    studyTypes: string;
    priority: string;
    findings: string;
    interpretation: string;
    internalMedicineRecommandations: string;
    signature: string;
    notes: string;
    requestMoreInfoMessage?: string;
    spayed?: boolean;
}

export interface IRadCaseFilesModel extends IModelBase {
    clinicId: number;
    caseId: number;
    createdOn?: Date;
    filePath: string;
    imageId?: number;
    userId?: number;
    isRadiologist?: boolean;
}

export interface IVersionInfoModel extends IModelBase {
    version: number;
    appliedOn?: Date;
    description: string;
}

export interface IBulkDownloadImagesRequest {
    clinicId: number;
    type: ImageFileType;
}

export interface IDicomSendRequest {
    imageIdList: number[];
    dicomServerId: number;
}

export interface IEmailSendRequest {
    imageIdList: number[];
    emailConfigId: number;
    emailReceiver: string[];
    emailSubject: string;
    emailBody?: string;
    appendDownoadLinkCheckbox?: boolean;
}

export interface IAnnotation {
    bodyPart: string;
    bodyPartView?: string;
    animalSize?: AnimalSizes;
}

export interface ICaptureXRayRequest {
    sensorId: number;
    studyId: number;
    annotation: IAnnotation;
}

export interface IDownloadImagesRequest {
    imageIds: number[];
    type: ImageFileType;
}

export interface IEditImageAnnotationRequest {
    imageId: number;
    annotation: IAnnotation;
}

export interface ISplitImageRequest {
    image: Blob;
}

export interface IPostPdfRequest {
    pdfFile: Blob;
    fileName: string;
    studyId: number;
}

export interface IFileIdStatus {
    id?: number;
    isFromReceiver: boolean;
    imageId?: number;
    fileName: string;
}

export interface IRadCaseResponse {
    id?: number;
    createdOn?: Date;
    status?: number;
    updateOn?: Date;
    submitOn?: Date;
    studyId: number;
    clinicId: number;
    radiologistId?: number;
    referringVeterinarianId: number;
    requesterId: number;
    patientId: number;
    patientName: string;
    sex: boolean;
    birthDate?: Date;
    neutered: boolean;
    breedId: number;
    speciesId: string;
    weight: number;
    weightUnit: string;
    ownerId: number;
    ownerName: string;
    address: string;
    phone: string;
    clinicHistory: string;
    additionalNotes: string;
    modality: string;
    studyTypes: string;
    priority: string;
    findings: string;
    interpretation: string;
    internalMedicineRecommandations: string;
    signature: string;
    notes: string;
    clinicName: string;
    referringVeterinarianName: string;
    requestMoreInfoMessage?: string;
}

export interface IRadCaseReportModel {
    id?: number;
    createdOn?: Date;
    status?: number;
    updateOn?: Date;
    submitOn?: Date;
    studyId: number;
    clinicId: number;
    radiologistId?: number;
    referringVeterinarianId: number;
    requesterId: number;
    patientId: number;
    patientName: string;
    sex: boolean;
    birthDate?: Date;
    neutered: boolean;
    breedId: number;
    speciesId: string;
    weight: number;
    weightUnit: string;
    ownerId: number;
    ownerName: string;
    address: string;
    phone: string;
    clinicHistory: string;
    additionalNotes: string;
    modality: string;
    studyTypes: string;
    priority: string;
    findings: string;
    interpretation: string;
    internalMedicineRecommandations: string;
    signature: string;
    notes: string;
    clinicName: string;
    referringVeterinarianName: string;
    imageIds: number[];
    numberOfReceviedImages: number;
    lastReceviedImageDate?: Date;
}

export interface IRadCaseStat {
    status?: number;
    count: number;
}

export interface IRadCaseReportStatItem {
    status?: number;
    id?: number;
    modality: string;
    priority: string;
    submitOn?: Date;
}

export interface ISensorInfo {
    width: number;
    height: number;
    id?: string;
    type: SensorTypes;
    brand: string;
    status?: ISensorStatus;
}

export interface ISensorStatus {
    state: SensorState;
    health: SensorHealth;
    connection: ConnectionType;
    battery?: number;
    latency?: number;
    temp?: number;
    isConnected: boolean;
    message: string;
    isOpen: boolean;
}

export enum SensorHealth {
    Ok = 'Ok',
    Warn = 'Warn',
    Error = 'Error',
}

export enum ConnectionType {
    Disconnected = 'Disconnected',
    Usb = 'Usb',
    Ethernet = 'Ethernet',
    Wifi = 'Wifi',
}

export enum SensorTypes {
    Unknown = 'Unknown',
    Ez = 'Ez',
    Hdr500 = 'Hdr500',
    Hdr600 = 'Hdr600',
    CR1500CW = 'CR1500CW',
    CR1500L = 'CR1500L',
    CR1500P = 'CR1500P',
    CR1800L = 'CR1800L',
}

export enum SensorState {
    NotAvailable = 'NotAvailable',
    Ready = 'Ready',
    PreparingSensor = 'PreparingSensor',
    WaitingForRadiation = 'WaitingForRadiation',
    RadiationReceived = 'RadiationReceived',
    LoadingImage = 'LoadingImage',
    PostAcquisitionCleanup = 'PostAcquisitionCleanup',
}
