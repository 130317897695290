






import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import { IEmailConfigModel } from '@/api/Models';

export default Vue.extend({
  props: {
    caseId: {
      type: String,
      required: true
    },
    isRadiologist: {
      type: Boolean,
      required: true
    },
    onClickCallback: {
      type: Function,
      default: () => undefined
    }
  },
  computed: {
    emailConfig: get<IEmailConfigModel | undefined>('email/config'),
  },
  methods: {
    async onClick (): Promise<void> {
      const { isConfirmed, value: additionalInfo } = await this.$swal.fire({
        title: 'Request For More Information',
        html: this.generateTextAreaHtml(),
        showCancelButton: true,
        confirmButtonText: 'Submit',
        preConfirm: () => {
          const textArea = document.getElementById('additionalInfo') as HTMLTextAreaElement;
          if (textArea) {
            return textArea.value;
          }
          return null;
        }
      });
      if (isConfirmed && additionalInfo) {
        const formData = new FormData();
        formData.append('caseId', this.caseId);
        formData.append('notes', additionalInfo);
        formData.append('isRadiologist', this.isRadiologist.toString());

        if (this.emailConfig?.id !== undefined) {
          formData.append('configId', this.emailConfig.id.toString());
        } else {
          formData.append('configId', '');
        }
        //
        try {
          dispatch('radcase/requestMoreInfo', formData);
          this.$router.push({ name: 'cases' });
        } catch (e) {
          console.log(e);
        }
      }
    },
    generateTextAreaHtml (): string {
      return `<div class="textarea-container">
                <textarea id="additionalInfo" placeholder="Enter additional information" rows="4" cols="50"></textarea>
              </div>`;
    }
  }
});
