


























import Vue from 'vue';
import dayjs from 'dayjs';
import { jsPDF as JsPDF } from 'jspdf';
import { commit, dispatch, get } from 'vuex-pathify';
import { getAge } from '@/state/viewer/tools/helpers';

import generatePDF from '@/helpers/pdf';
import Page from './components/Page.vue';
import { IRadCaseModel, IRadCaseReportModel, IStudieModel } from '@/api/Models';
import { IReportPage } from '@/state/report';

const IMAGES_PER_PAGE = 6;
export default Vue.extend({
  components: { Page },
  data () {
    return {
      resetOnComplete: false,
      radCaseReport: null as IRadCaseReportModel | null,
      files: [] as { url: string; mimeType: string }[],
      age: ''
    };
  },
  computed: {
    pages: get<IReportPage[]>('report/pages'),
    selectedStudy: get<IStudieModel>('study/selectedStudy'),
    selectedCase: get<IRadCaseModel>('radcase/radcase')
  },
  async mounted () {
    this.radCaseReport = await this.$store.dispatch('radcase/fetchCaseForReport', this.selectedCase.id);
    this.age = getAge(this.radCaseReport?.birthDate);
    if (this.radCaseReport && this.radCaseReport?.imageIds) {
      for (const imageId of this.radCaseReport?.imageIds) {
        const fileData = await this.fetchFileById(imageId);
        if (fileData) {
          this.files.push({
            url: fileData.url,
            mimeType: fileData.mimeType
          });
        }
      }
      this.prepareReportPages();
    }
  },
  methods: {
    async prepareReportPages (): Promise<void> {
      // Ensure the first page is created for the report text
      if (this.pages.length === 0) {
        await dispatch('report/createPage', 0); // Create the first page without images
      }

      const imagesPerPage = IMAGES_PER_PAGE;
      const totalPages = Math.ceil(this.files.length / imagesPerPage);

      for (let i = 0; i < totalPages; i++) {
        const imagesOnPage = this.files.slice(i * imagesPerPage, (i + 1) * imagesPerPage);
        await dispatch('report/createPage', imagesOnPage.length);
      }
    },
    getFilesForPage (pageIndex: number) {
      const imagesPerPage = IMAGES_PER_PAGE;
      // Skip the first page index as it's reserved for the text fields
      const imagePageIndex = pageIndex - 1;
      if (imagePageIndex < 0) return [];
      return this.files.slice(imagePageIndex * imagesPerPage, (imagePageIndex + 1) * imagesPerPage);
    },
    async onBackClick (): Promise<void> {
      const result = await this.$swal.fire({
        title: 'Go Back?',
        text: 'The reports will not be saved',
        showConfirmButton: true,
        showCancelButton: true
      });

      if (result.isConfirmed) {
        commit('report/flush');
        this.$router.go(-1);
      }
    },
    async addNewPage (): Promise<void> {
      // Create an empty text page
      await dispatch('report/createPage', 0);
    },

    async generatePDF (): Promise<void> {
      const promises = this.pages.map((page, index) => dispatch('report/disablePage', index));
      await Promise.all(promises);

      this.resetOnComplete = false;

      const pageContainerRefs = this.$refs.pageRefs as HTMLElement[];
      const pageRefs = pageContainerRefs.map(pageRef => pageRef.firstChild) as HTMLElement[];

      const format = [279 * 3.7, 216 * 3.7]; // 1:1.2941 Aspect Ratio
      const doc = new JsPDF({ unit: 'px', format });

      try {
        await generatePDF(pageRefs, doc);
        const fileName = dayjs().format('YYYY-MM-DD_HH.mm.ss') + '.pdf';
        doc.save(fileName);
      } catch (e) {
        console.log(e);
      } finally {
        this.resetOnComplete = true;
      }
    },
    async fetchFileById (fileId: number): Promise<{ url: string; mimeType: string } | null> {
      try {
        const fileBlob = await this.$store.dispatch('radcase/fetchCaseFileById', fileId);
        const fileUrl = URL.createObjectURL(fileBlob);
        return {
          url: fileUrl,
          mimeType: fileBlob.type
        };
      } catch (error) {
        console.error('Error fetching file:', error);
        return null;
      }
    }
  }
});
